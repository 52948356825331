import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ConnexionService } from '../services/connexion/connexion.service';
import { NavigationService } from '../services/navigation/navigation.service';
import { StorageService } from '../services/storage/storage.service';
import { SharedService } from '../services/shared/shared.service';
import { Autologin } from '../interfaces/ConnexionOut';

@Component({
  selector: 'app-autologin',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './autologin.component.html',
  styleUrl: './autologin.component.css'
})
export class AutologinComponent 
{
  constructor(
    private activatedRoute: ActivatedRoute,
    private connexionService: ConnexionService,
    private storageService: StorageService,
    private navigationService: NavigationService,
    private sharedService: SharedService
  ){}

  ngOnInit()
  {
    this.activatedRoute.queryParamMap.subscribe((paramsMap:any) => {
      const params:any = paramsMap.params

      const dataMap = new Map()

      for (const key in params) 
      {
        if (Object.prototype.hasOwnProperty.call(params, key))
        { 
          let propertyName
          switch (key) 
          {
            case "ida":
              propertyName = "id_user_abo"
            break;
            case "idc":
              propertyName = "id_user_crm"
            break;
            case "idp":
              propertyName = "email_user_contact"
            break;
            case "h":
              propertyName = "horodatage"
            break;
          }

          if(key == "idc") 
          {
            dataMap.set(propertyName, params[key])
          }
          else
          {
            //* En "convertissant la fonction de l'asp, on se retrouve avec des caracctères bizarres en début de chaine"
            dataMap.set(propertyName, this.decryptMail(params[key].trim()).substring(3)) //*
          }
        }
      }

      this.connexionService.autologin(dataMap).subscribe((data:Autologin) => {

        if( !data.access_token || !data.refresh_token || !data.id_user )
          {
            alert("Une erreur est survenue lors de la connexion")
            return
          }
    
          for (const property in data)
          {
            const propertyValue:string = property.toString()
            this.storageService.set(property.toString() , data[propertyValue].toString())
          }
    
          const token = data.access_token
          const userCRMId = JSON.parse(data.userCRM).USER_ID
          
          if( token && this.connexionService.isTokenExpired() == false ) 
          {
            // ID 474 : Connexion automatique depuis le CRM
            this.sharedService.postTableConnexion(userCRMId, 474, data.id_user).subscribe(() => {
              if(data)
              {
                this.navigationService.navigateTo('',[null])
                return
              }
            })
          }
      })
    })
  }

  
  // decryptMail(str:string)
  // {
  //   str
  //   .replace("dpRiHMnCiZ", "0")
  //   .replace("FVcJkkkqJE", "1")
  //   .replace("GRsRkPgeFypZ", "2")
  //   .replace("DxFKtnRsgH", "3")
  //   .replace("SRXirPsjgC", "4")
  //   .replace("NcuBjXbBzK", "5")
  //   .replace("WWiHJettdV", "6")
  //   .replace("JkmLVzMvnE", "7")
  //   .replace("hWyXBNqtYa", "8")
  //   .replace("vpKaSLppTZ", "9")
  //   .replace("nVkkNHNrm", "=")
  //   return btoa(str)
  // }

  decryptMail(stringToDecrypt: string) 
  {
    if(typeof stringToDecrypt !== "string" ) return stringToDecrypt
    
    let str = stringToDecrypt

    str = str.replace(/dpRiHMnCiZ/g, "0")
    str = str.replace(/FVcJkkkqJE/g, "1")
    str = str.replace(/GRsRkPgeFypZ/g, "2")
    str = str.replace(/DxFKtnRsgH/g, "3")
    str = str.replace(/SRXirPsjgC/g, "4")
    str = str.replace(/NcuBjXbBzK/g, "5")
    str = str.replace(/WWiHJettdV/g, "6")
    str = str.replace(/JkmLVzMvnE/g, "7")
    str = str.replace(/hWyXBNqtYa/g, "8")
    str = str.replace(/vpKaSLppTZ/g, "9")
    str = str.replace(/nVkkNHNrm/g, "=")

    return atob(str)
  }

  // NoTagUTF8(champ:string)
  // {
  //   champ = champ.trim()
  //   champ = champ.replace(champ,"'","''")
  //   champ = champ.replace(champ,"`","''")
  //   champ = champ.replace(champ,"’","''")
  //   champ = champ.replace(champ,"&#39;","''")
  //   return champ
  // }

}
