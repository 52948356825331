<main class="main bg-grey-light">
    <div class="main-header">
        <div class="ariane">
            <img class="picto cursor" src="/assets/img/picto-home.png" alt="">
            <div class="item small cursor"></div>
        </div>
        <h1 class="grey">Tableau de bord</h1>
    </div>


    <div class="main-content row d-flex justify-content-center">

        <div class="tile tile-03 ongoing-folders bg-white blue cursor" (click)="nb_en_cours > 0 ? goToDossiersEnCours() :''"  [ngClass]="nb_en_cours > 0 ? 'cursor': ''">
            <div class="half left data-number">{{ nb_en_cours }}</div>
            <div class="half right data-label">Dossier@if( nb_en_cours > 1 ){s} en cours</div>
        </div>

        <div class="tile tile-02 unread-messages bg-white blue orange" (click)="nb_message_non_lus > 0 ? goToMessageNonLus() :''" [ngClass]="nb_message_non_lus > 0 ? 'cursor': ''">
            <div class="half left data-number">{{ nb_message_non_lus }}</div>
            <div class="half right data-label">Message@if( nb_message_non_lus > 1 ){s} non lu@if( nb_message_non_lus > 1 ){s}</div>
        </div>

        <div class="w-100"></div>

        <div class="tile tile-02 unread-documents bg-white blue orange" (click)="nb_document_non_lus > 0 ? goToDocumentNonLus() :''" [ngClass]="nb_document_non_lus > 0 ? 'cursor': ''">
            <div class="half left data-number">{{ nb_document_non_lus }}</div>
            <div class="half right data-label">Document@if( nb_document_non_lus > 1 ){s} non consulté@if( nb_document_non_lus > 1 ){s}</div>
        </div>

        <div class="tile tile-list tile-04 recently-opened-folders bg-white">
            <h2 class="tile-header bg-blue white">
                @if( derniers_messages.length > 1 ){Derniers messages reçus}
                @else{Dernier message reçu}
            </h2>
            <div class="tile-content">
                <!-- @if(derniers_messages.length == 0)
                {
                    <div class="tile-content-row blue normal text-center"> 
                        Aucun message reçu
                    </div>
                } -->
                @for (item of derniers_messages; track item) 
                {  
                    <div class="tile-content-row blue normal"> 
                        <a class="cursor" (click)="goToMessage(item.REF, item.ID_DOSSIER)">{{item.REF}} - {{ item.MESSAGE }} </a>
                    </div>
                }
            </div>
        </div>

        <div class="w-100"></div>

        <div class="tile tile-small bg-white blue" (click)="nb_clos > 0 ? goToDossiersClos() :''"  [ngClass]="nb_clos > 0 ? 'cursor': ''">
            <div class="data-number">{{ nb_clos }}</div>
            <div class="data-label">Dossier@if( nb_clos > 1 ){s} clos</div>
        </div>

        <div class="tile tile-small bg-white blue cursor" (click)="goToProfil()">
            <div class="data-label me-3">Voir mon profil</div>
            <span class="bg-blue rounded-container">
                <!-- <img src="/assets/icons/triangle.png"  class="white-filter rotate-right" height="15"> -->
                <svg width="11" height="11" viewBox="0 0 100 100">
                    <polygon class="triangle" points="10,10 90,50 10,90" />
                </svg>
            </span>
        </div>

    </div>

</main>