<main class="main">
    <app-breadcrumb [title]="title"></app-breadcrumb>

    <h3 class="grey-dark h1">{{ title }}</h3>

    <form class="profil bg-grey-light border-dark mt-4 col-6" [formGroup]="profil">
        <ul>
            @for (item of data; track $index) 
            {
                <li class="profil-item">
                    <div class="col-3">{{ item.name }}</div>

                    @if(item.value)
                    {
                        <div class="col-9 bolder text-break">{{ item.value }}</div>
                    }
                    @else
                    {
                        <div class="col-9">-</div>
                    }
                </li>
            }

            
            @if (!isPasswordSectionVisible) 
            {
                <button type="button" class="bg-blue white no-border save-profil" (click)="togglePasswordSection()">Changer mon mot de passe</button>
            }

            @if (isPasswordSectionVisible)
            {
                <section>
                    <li class="profil-item password-item">
                        <div class="col-4">Saisissez votre mot de passe actuel</div>
                        <input class="col-8" type="password" formControlName="password">
                    </li>
                    <p class="red bold offset-1"><em><p>{{ errorMessage }}</p></em></p>

                    @if( passwordCheck )
                    {
                        <li class="profil-item password-item">
                            <div class="col-4">Nouveau mot de passe</div>
                            <input 
                                class="col-8" 
                                type="password" 
                                formControlName="new_password" 
                                [maxlength]="limit"
                                [ngClass]="profil.get('new_password')?.value.length >= limit ? 'red': ''">
                            <img class="password-icon" [src]="srcIconPassword" alt="montrer ou masquer le nouveau mot de passe" (click)="togglePasswordVisibility($event)">
                        </li>
                        <p class="red bold offset-1" *ngIf="isSubmitted && isInvalid('new_password') && profil.get('new_password')?.errors"><em><p>* La longueur du mot de passe doit être de 8 caractères au minimum</p></em></p>
                        <p class="red bold offset-1" *ngIf="profil.get('new_password')?.value.length >= limit"><em><p>Vous avez atteint la limite du nombre de caractères autorisé</p></em></p>
    
                        <li class="profil-item password-item">
                            <div class="col-4">Confirmez le mot de passe</div>
                            <input 
                                class="col-8" 
                                type="password" 
                                formControlName="confirm_password" 
                                [maxlength]="limit"
                                [ngClass]="profil.get('confirm_password')?.value.length >= limit ? 'red': ''">
                            <img class="password-icon" [src]="srcIconPassword" alt="montrer ou masquer le nouveau mot de passe" (click)="togglePasswordVisibility($event)">
                        </li>
                        <p class="red bold offset-1" *ngIf="isSubmitted && isInvalid('confirm_password') && profil.get('confirm_password')?.errors"><em><p>* La longueur du mot de passe doit être de 8 caractères au minimum</p></em></p>
                        <p class="red bold offset-1" *ngIf="profil.get('confirm_password')?.value.length >= limit"><em><p>Vous avez atteint la limite du nombre de caractères autorisé</p></em></p>
                    }

                </section>

                @if( passwordCheck )
                {
                    <button type="button" class="bg-blue white no-border save-profil" (click)="submit()">Enregistrer le mot de passe</button>
                }
                @else
                {
                    <button type="button" class="bg-blue white no-border save-profil" (click)="checkPassword()" [disabled]="profil.get('password')?.value?.length == 0">Vérifier</button>
                }
                <p class="red bold offset-1" *ngIf="isSubmitted && profil.getError('passwordMismatch')"><em><p>* Les mots de passe ne concordent pas</p></em></p>
            }
        </ul>
    </form>

</main>
