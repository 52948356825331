import { Component } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ProfilService } from '../../services/profil/profil.service';
import { confirmPasswordValidator } from '../../helpers/custom-validators/password.validator';
import { SharedModule } from '../../modules/shared/shared.module';
import { ProfilIn, ProfilOut } from '../../interfaces/Profil';
import { SharedService } from '../../services/shared/shared.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ConnexionService } from '../../services/connexion/connexion.service';
import { FormatPhoneNumberPipe } from "../../helpers/pipes/format-phone-number/format-phone-number.pipe";
import { BreadcrumbComponent } from '../../common/breadcrumb/breadcrumb.component';


// const upperLowerSymbolNumberRegex = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*\W)/;
@Component({
    selector: 'app-profil',
    standalone: true,
    templateUrl: './profil.component.html',
    styleUrl: './profil.component.css',
    imports: [SharedModule, FormatPhoneNumberPipe, BreadcrumbComponent]
})

export class ProfilComponent 
{
  title:string = "Mon profil"
  srcIconPassword:string = "/assets/icons/picto-oeil-cacher.png"
  hide_password:string = "/assets/icons/picto-oeil-cacher.png"
  show_password:string = "/assets/icons/picto-oeil-montrer.png"
  profil!: FormGroup
  isPasswordSectionVisible: boolean = false
  idUser!:number

  data: any = []
  srcIconTrue: string = "/assets/icons/picto-valide.png"
  srcIconFalse: string = "/assets/icons/picto-croix-rouge.png"
  passwordCheck:boolean = false
  errorMessage:string = ""
  isSubmitted:boolean = false
  limit:number = Object.freeze(200)

  constructor
  (
    private profilService:ProfilService,
    private connexionService:ConnexionService,
    private sharedService: SharedService,
    private snackBar: MatSnackBar
  )
  {
    const id_user = this.sharedService.getIdUser()
    this.idUser = id_user

    this.getProfil()
    
    // const validators = [Validators.minLength(8), Validators.pattern(upperLowerSymbolNumberRegex)]
    const validators = [Validators.minLength(8)]

    this.profil = new FormGroup({
      password: new FormControl(""),
      new_password: new FormControl("", validators),
      confirm_password: new FormControl("", validators)
    },
    {
      validators: confirmPasswordValidator('new_password', 'confirm_password')
    })
  }

  getProfil()
  {
    this.profilService.getProfil(this.idUser).subscribe((data:ProfilIn[]) => {
      this.data = 
      [
        {name:"Civilité", value:data[0].civilite},
        {name:"Nom", value:data[0].nom},
        {name:"Prénom", value:data[0].prenom},
        {name:"Courriel", value:data[0].email}
      ]

      if(data[0].id_profil == 5 && data[0].entreprise != null)
      {
        this.data.unshift({name:"Entreprise", value:data[0].entreprise})
      }
    })
  }

  togglePasswordSection()
  {
    this.isPasswordSectionVisible = !this.isPasswordSectionVisible
  }

  togglePasswordVisibility(event: Event)
  {
    if(event.target instanceof HTMLImageElement)
    { 
      const img: HTMLImageElement = event.target

      if( img.src.split("assets")[1] === this.hide_password.split("assets")[1] ) 
      {
        img.src = this.show_password
        event.target.previousElementSibling?.setAttribute("type","text")
      }
      else 
      {
        img.src = this.hide_password
        event.target.previousElementSibling?.setAttribute("type","password")
      }
    }
  }

  isInvalid(controlName: string)
  {
    const control = this.profil.get(controlName)
    return control?.invalid && (control.dirty || control.touched)
  }

  formInvalid()
  {
    this.profil.markAsDirty()
    this.profil.markAllAsTouched()
    console.log("invalid")
    return
  }

  checkPassword()
  {
    const password = this.profil.controls["password"].value
    this.profilService.checkPassword(password, this.idUser).subscribe(data => {
      if(data)
      {
        this.errorMessage = ""
        this.passwordCheck = true
      }
      else
      {
        this.errorMessage = "Le mot de passe saisi n'est pas correct, veuillez recommencer"
        this.formInvalid()
      }
    })
  }

  submit()
  {
    const new_password = this.profil.controls["new_password"].value
    const confirm_password = this.profil.controls["confirm_password"].value

    this.isSubmitted = true

    if(
        this.profil.invalid || 
        new_password !== confirm_password ||
        new_password === "" || confirm_password === "" ||
        new_password.length < 8 || confirm_password.length < 8
      )
    {
      return this.formInvalid()
    }

    const form:ProfilOut =  { data: new_password, idUser: this.idUser}

    this.profilService.saveProfil(form).subscribe({
      next: (id_user) => {
        this.isSubmitted = false

        const snackBarRef = this.snackBar.open("Votre mot de passe à bien été modifié, vous allez bientôt être déconnecté", "Ok", {
          duration: 4000,
        })

        this.sharedService.postTableConnexion(this.idUser, 3, this.idUser).subscribe()

        snackBarRef.afterDismissed().subscribe(() => {
          this.connexionService.logout()
        })
      },
      error: (e:Error) => {
        console.log(e)

        this.snackBar.open(e.message, "J'ai compris", {
          duration: 5000,
        })
      }
    })
  }
}
