import { Component, HostListener } from '@angular/core';
import { SharedModule } from '../modules/shared/shared.module';
import { ReturnTopButtonComponent } from "../common/return-top-button/return-top-button.component";
import { SharedService } from '../services/shared/shared.service';
import { MenuBurgerComponent } from "../common/menu-burger/menu-burger.component";
import { BackdropComponent } from "../common/backdrop/backdrop.component";

@Component({
    selector: 'app-container',
    standalone: true,
    templateUrl: './app-container.component.html',
    styleUrl: './app-container.component.css',
    imports: [SharedModule, ReturnTopButtonComponent, MenuBurgerComponent, BackdropComponent]
})
export class AppContainerComponent 
{
    constructor
    (
        private sharedService: SharedService
    ) {}

    @HostListener('window:resize', ['$event'])
    onWindowResize() 
    {
        if(window.innerWidth >= 1400) 
        {
            (document.getElementById("sidebar") as HTMLElement).style.display = 'initial'
        }
        if(window.innerWidth < 1400) 
        {
            (document.getElementById("sidebar") as HTMLElement).style.display = 'none'
        }
    }

    switchToTabletMode()
    {
      return this.sharedService.switchToTabletMode()
    }
}
