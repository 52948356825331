import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { DataDashboard, DataDashboardMessage } from '../../interfaces/DataDashboard';
import { Dossiers } from '../../interfaces/Dossiers';
import { MessageIn } from '../../interfaces/MessageIn';

@Injectable({
  providedIn: 'root'
})
export class DashboardService 
{
  private readonly HOST:string  = environment.BASE_URL

  constructor(
    private httpClient: HttpClient
  ) { }

  countMessagesNonLus(id_utilisateur:number):Observable<{total_result:number}[]>
  {
    const url = `${this.HOST}/countMessagesNonLus`

    return this.httpClient.get<{total_result:number}[]>(url, 
      {
        params : 
        {
          id_utilisateur
        }
      })
  }

  countDocumentsNonLus(id_utilisateur:number):Observable<{total_result:number}[]>
  {
    const url = `${this.HOST}/countDocumentsNonLus`

    return this.httpClient.get<{total_result:number}[]>(url, 
      {
        params : 
        {
          id_utilisateur
        }
      })
  }

  countDossiersEnCours(id_utilisateur:number):Observable<{total_result:number}[]>
  {
    const url = `${this.HOST}/countDossiersEnCours`

    return this.httpClient.get<{total_result:number}[]>(url, 
      {
        params : 
        {
          id_utilisateur
        }
      })
  }

  countDossiersClos(id_utilisateur:number):Observable<{total_result:number}[]>
  {
    const url = `${this.HOST}/countDossiersClos`

    return this.httpClient.get<{total_result:number}[]>(url, 
      {
        params : 
        {
          id_utilisateur
        }
      })
  }

  getLastMessages(id_utilisateur:number):Observable<DataDashboardMessage[]>
  {
    const url = `${this.HOST}/getLastMessages`

    return this.httpClient.get<DataDashboardMessage[]>(url, 
      {
        params : 
        {
          id_utilisateur
        }
      })
  }


  getDossiersEnCours(pageIndex:number, pageSize:number, id:number, search:string=""):Observable<Dossiers[]>
  {
    const url = `${this.HOST}/tableau-de-bord/dossiers-en-cours`

    return this.httpClient.get<Dossiers[]>(url,
      {
        params : 
        {
          pageIndex:pageIndex,
          pageSize:pageSize,
          id:id,
          search:search
        }
      })
  }

  getDossiersClos(pageIndex:number, pageSize:number, id:number, search:string=""):Observable<Dossiers[]>
  {
    const url = `${this.HOST}/tableau-de-bord/dossiers-clos`

    return this.httpClient.get<Dossiers[]>(url,
      {
        params : 
        {
          pageIndex:pageIndex,
          pageSize:pageSize,
          id:id,
          search:search
        }
      })
  }

  getMessagesNonLus(pageIndex:number, pageSize:number, id:number, search:string=""):Observable<MessageIn[]>
  {
    const url = `${this.HOST}/tableau-de-bord/messages-non-lus`

    return this.httpClient.get<MessageIn[]>(url,
      {
        params : 
        {
          pageIndex:pageIndex,
          pageSize:pageSize,
          id:id,
          search:search
        }
      })
  }

  getDocumentsNonLus(pageIndex:number, pageSize:number, id:number, search:string=""):Observable<Document[]>
  {
    const url = `${this.HOST}/tableau-de-bord/documents-non-lus`

    return this.httpClient.get<Document[]>(url,
      {
        params : 
        {
          pageIndex:pageIndex,
          pageSize:pageSize,
          id:id,
          search:search
        }
      })
  }
}
