import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { SuiviComponent } from '../../../pages/suivi.component';
import { DocumentComponent } from '../../../pages/documents.component';
import { TransmettreComponent } from '../../../pages/transmettre.component';
import { MessageComponent } from '../../../pages/messages.component';
import { DossiersService } from '../../../services/dossiers/dossiers.service';
import { Dossiers_Data } from '../../../interfaces/Dossiers';
import { SharedService } from '../../../services/shared/shared.service';
import { BreadcrumbComponent } from "../../../common/breadcrumb/breadcrumb.component";
import { NavigationService } from '../../../services/navigation/navigation.service';
import { verif } from '../../../helpers/decorators/verif';

@Component({
    selector: 'app-dossier-details',
    standalone: true,
    templateUrl: './dossier-details.component.html',
    styleUrl: './dossier-details.component.css',
    imports: [SuiviComponent, DocumentComponent, TransmettreComponent, CommonModule, MessageComponent, BreadcrumbComponent]
})
export class DossiersDetailsComponent 
{
  parent:string = 'Dossier'
  title!:string
  civilite!:string
  abonne!:string
  num_contrat1!:string
  num_contrat2!:string
  adresse!:string
  cp!:string
  ville!:string
  date!:Date
  date_realisation_previ!:Date
  hors_delai!:number
  etat!:string
  ref!:string
  id_dossier!:number
  id_user!:number
  imgSource:string = '/assets/icons/picto-transmettreenvoi-white.png'

  parentData!:Dossiers_Data

  readonly tabList = Object.freeze(["suivi","documents","messages","transmettre"])

  constructor
  (
    private sharedService: SharedService,
    private dossiersService: DossiersService,
    private activatedRoute: ActivatedRoute,
    private navigationService: NavigationService,
    private router: Router
  ) 
  {
    this.ref = this.sharedService.getParam("ref")?.toString() || ""
    this.id_dossier = Number(this.sharedService.getParam("id_dossier"))
    this.id_user = this.sharedService.getIdUser()

    this.parentData = {ref:this.ref, id_dossier:this.id_dossier, type:"dossiers"}
  }

  ngOnInit()
  {
    this.activatedRoute.paramMap.subscribe(params => {
      this.title = `${this.parent} ${this.ref}`
      const id_dossier = this.activatedRoute.snapshot.queryParams['id_dossier']
      this.parentData.id_dossier = id_dossier
      this.getDetails({ref:this.ref, id_user:this.id_user, id_dossier:this.id_dossier})
    })
  }

  ngAfterViewInit() 
  {
    const onglet = this.sharedService.getParam("onglet")?.toString()

    if( onglet )
    {
      (document.querySelector(`#${onglet}`) as HTMLButtonElement).click()
    }
  }

  @verif()
  getDetails(params:{ref:string, id_user:string|number, id_dossier:number})
  {
    this.dossiersService.getDetails(params.ref, params.id_user).subscribe(data => {
      this.civilite = data[0].civilite
      this.abonne = data[0].abonne
      this.num_contrat1 = data[0].num_contrat1
      this.num_contrat2 = data[0].num_contrat2
      this.adresse = data[0].adresse
      this.cp = data[0].cp
      this.ville = data[0].ville
      this.date = data[0].date
      this.date_realisation_previ = data[0].date_realisation_previ
      this.etat = data[0].etat
      this.hors_delai = data[0].hors_delai
    })
  }

  changeSource()
  {
    this.imgSource = '/assets/icons/picto-transmettreenvoi-white.png'

  }

  defaultSource()
  {
    this.imgSource = '/assets/icons/picto-transmettre-black.png'
  }

  changeTab(tab:string)
  {
    if(this.tabList.includes(tab))
    {
      const ref = this.sharedService.getParam("ref")?.toString()
      const id_dossier = this.sharedService.getParam("id_dossier")
      this.navigationService.navigateTo(`dossiers/details/ref_${ref}/id_dossier_${id_dossier}/onglet_${tab}`, [null])
      // this.router.navigate(['/some-url'], { queryParams:  filter, skipLocationChange: true});
    }
  }
}
